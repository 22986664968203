import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { AxiosBaseUrl } from '../../../config/axios-configuration';

import { ENDPOINTS } from '../../../utils/constants';

const axios = AxiosBaseUrl();

const initialState = {
  emailsData: null,
  loading: false,
  errMessage: null,
  success: false
};

export const AddEmail = createAsyncThunk(
  ENDPOINTS.EMAIL.ADD_EMAIL,
  async ({
    name,
    email
  }, {
    rejectWithValue
  }) => {
    try {
      const response = await axios.post(ENDPOINTS.EMAIL.ADD_EMAIL, {
        name,
        email
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const DeleteEmail = createAsyncThunk(
  ENDPOINTS.EMAIL.DELETE_EMAIL,
  async ({
    emailId
  }, {
    rejectWithValue
  }) => {
    try {
      const response = await axios.post(ENDPOINTS.EMAIL.DELETE_EMAIL, {
        emailId
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const GetAllEmails = createAsyncThunk(
  ENDPOINTS.EMAIL.GET_ALL_EMAIL,
  async ({
    searchKeyword,
    skip,
    limit
  }, { rejectWithValue }) => {
    try {
      let url = `${ENDPOINTS.EMAIL.GET_ALL_EMAIL}?skip=${skip}&limit=${limit}`;

      if (searchKeyword) {
        url += `&searchKeyword=${searchKeyword}`;
      }
      const response = await axios.get(url);

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        error: 'Network Error'
      });
    }
  }
);

export const UpdateEmail = createAsyncThunk(
  ENDPOINTS.EMAIL.UPDATE_EMAIL,
  async ({
    emailId,
    name,
    email,
    stores
  }, {
    rejectWithValue
  }) => {
    try {
      const response = await axios.post(ENDPOINTS.EMAIL.UPDATE_EMAIL, {
        emailId,
        name,
        email,
        stores
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

const email = createSlice({
  name: 'emailReducer',
  initialState,
  reducers: {
    SetEmailState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddEmail.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(AddEmail.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(AddEmail.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(GetAllEmails.pending, (state) => ({
        ...state,
        loading: true
      }))
      .addCase(GetAllEmails.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        emailsData: action.payload
      }))
      .addCase(GetAllEmails.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload?.errorMessage || '',
        loading: false
      }));
    builder
      .addCase(DeleteEmail.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(DeleteEmail.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(DeleteEmail.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(UpdateEmail.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(UpdateEmail.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(UpdateEmail.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
  }
});

export const { SetEmailState } = email.actions;
export default email.reducer;
