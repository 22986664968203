import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { AxiosBaseUrl } from '../../../config/axios-configuration';

import { ENDPOINTS } from '../../../utils/constants';

const { REACT_APP_API_URL } = process.env;

const axios = AxiosBaseUrl();

const initialState = {
  productData: null,
  loading: false,
  errMessage: null,
  success: false,
  productCosts: null
};

export const GetParentASINSummary = createAsyncThunk(
  ENDPOINTS.SUMMARY.GET_PARENT_ASIN_SUMMARY,
  async ({
    storeId,
    startDate,
    endDate,
    sortKey,
    sortOrder,
    skip,
    limit,
    searchValue
  }, {
    rejectWithValue
  }) => {
    try {
      let url = `${ENDPOINTS.SUMMARY.GET_PARENT_ASIN_SUMMARY}?storeId=${storeId}&skip=${skip}&limit=${limit}`;

      if (startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (sortKey && sortOrder) {
        url += `&sortKey=${sortKey}&sortOrder=${sortOrder}`;
      }

      if (searchValue) {
        url += `&searchValue=${searchValue}`;
      }

      const response = await axios.get(url);

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const GetChildASINSummary = createAsyncThunk(
  ENDPOINTS.SUMMARY.GET_CHILD_ASIN_SUMMARY,
  async ({
    storeId,
    startDate,
    endDate,
    sortKey,
    sortOrder,
    skip,
    limit,
    searchValue
  }, {
    rejectWithValue
  }) => {
    try {
      let url = `${ENDPOINTS.SUMMARY.GET_CHILD_ASIN_SUMMARY}?storeId=${storeId}&skip=${skip}&limit=${limit}`;

      if (startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (sortKey && sortOrder) {
        url += `&sortKey=${sortKey}&sortOrder=${sortOrder}`;
      }

      if (searchValue) {
        url += `&searchValue=${searchValue}`;
      }

      const response = await axios.get(url);

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const DownloadParentAsinReport = createAsyncThunk(
  ENDPOINTS.NON_SECURE_ROUTES.DOWNLOAD_PARENT_ASIN_SUMMARY,
  async ({
    storeId
  }, {
    rejectWithValue
  }) => {
    try {
      const url = `${REACT_APP_API_URL}${ENDPOINTS.NON_SECURE_ROUTES.DOWNLOAD_PARENT_ASIN_SUMMARY}?storeId=${storeId}`;

      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);

      return true;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const DownloadChildAsinReport = createAsyncThunk(
  ENDPOINTS.NON_SECURE_ROUTES.DOWNLOAD_CHILD_ASIN_SUMMARY,
  async ({
    storeId
  }, {
    rejectWithValue
  }) => {
    try {
      const url = `${REACT_APP_API_URL}${ENDPOINTS.NON_SECURE_ROUTES.DOWNLOAD_CHILD_ASIN_SUMMARY}?storeId=${storeId}`;

      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);

      return true;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const DownloadCogsAcosReport = createAsyncThunk(
  ENDPOINTS.NON_SECURE_ROUTES.SAVE_COGS_ACOS_REPORT_IDS,
  async ({
    storeId,
    productIds
  }, {
    rejectWithValue
  }) => {
    try {
      const response = await axios.post(ENDPOINTS.NON_SECURE_ROUTES.SAVE_COGS_ACOS_REPORT_IDS, {
        storeId,
        productIds
      });

      if (response.data._id) {
        const url = `${REACT_APP_API_URL}${ENDPOINTS.NON_SECURE_ROUTES.DOWNLOAD_COGS_ACOS_REPORT}?_id=${response.data._id}`;

        setTimeout(() => {
          window.open(url, '_blank');
        }, 0);
      }

      return true;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const GetProductCosts = createAsyncThunk(
  ENDPOINTS.PRODUCT.GET_COST_AND_FIELDS,
  async ({
    productId
  }, {
    rejectWithValue
  }) => {
    try {
      const response = await axios.get(`${ENDPOINTS.PRODUCT.GET_COST_AND_FIELDS}?productId=${productId}`);

      return response.data.response;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const UpdateProduct = createAsyncThunk(
  ENDPOINTS.PRODUCT.UPDATE_PRODUCT_FEES,
  async ({
    productId,
    manufacturingCost,
    shippingCost
  }, {
    rejectWithValue
  }) => {
    try {
      const response = await axios.post(ENDPOINTS.PRODUCT.UPDATE_PRODUCT_FEES, {
        productId,
        manufacturingCost,
        shippingCost
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

const product = createSlice({
  name: 'productReducer',
  initialState,
  reducers: {
    SetProductState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetParentASINSummary.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GetParentASINSummary.fulfilled, (state, action) => ({
        ...state,
        success: true,
        loading: false,
        productData: action.payload
      }))
      .addCase(GetParentASINSummary.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(GetChildASINSummary.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GetChildASINSummary.fulfilled, (state, action) => ({
        ...state,
        success: true,
        loading: false,
        productData: action.payload
      }))
      .addCase(GetChildASINSummary.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(DownloadParentAsinReport.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(DownloadParentAsinReport.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(DownloadParentAsinReport.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(DownloadChildAsinReport.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(DownloadChildAsinReport.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(DownloadChildAsinReport.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(GetProductCosts.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GetProductCosts.fulfilled, (state, action) => ({
        ...state,
        success: true,
        loading: false,
        productCosts: action.payload
      }))
      .addCase(GetProductCosts.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(DownloadCogsAcosReport.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(DownloadCogsAcosReport.fulfilled, (state, action) => ({
        ...state,
        success: true,
        loading: false,
        productCosts: action.payload
      }))
      .addCase(DownloadCogsAcosReport.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(UpdateProduct.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(UpdateProduct.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(UpdateProduct.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload?.errorMessage || '',
        success: false,
        loading: false
      }));
  }
});

export const { SetProductState } = product.actions;
export default product.reducer;
