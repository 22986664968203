import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { AxiosBaseUrl } from '../../../config/axios-configuration';

import { ENDPOINTS } from '../../../utils/constants';

const { REACT_APP_API_URL } = process.env;

const axios = AxiosBaseUrl();

const initialState = {
  summaryData: null,
  fileData: null,
  loading: false,
  errMessage: null,
  success: false
};

export const GetSummarySnapShot = createAsyncThunk(
  ENDPOINTS.SUMMARY.GET_SUMMARY_SNAP_SHOT,
  async ({
    storeId,
    startDate,
    endDate,
    sortKey,
    sortOrder
  }, {
    rejectWithValue
  }) => {
    try {
      let url = `${ENDPOINTS.SUMMARY.GET_SUMMARY_SNAP_SHOT}?storeId=${storeId}`;

      if (startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (sortKey && sortOrder) {
        url += `&sortKey=${sortKey}&sortOrder=${sortOrder}`;
      }

      const response = await axios.get(url);

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const DownloadWeeklySnapShot = createAsyncThunk(
  ENDPOINTS.NON_SECURE_ROUTES.DOWNLOAD_WEEKLY_SNAPSHOT,
  async ({
    storeId
  }, {
    rejectWithValue
  }) => {
    try {
      const url = `${REACT_APP_API_URL}${ENDPOINTS.NON_SECURE_ROUTES.DOWNLOAD_WEEKLY_SNAPSHOT}?storeId=${storeId}`;

      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);

      return true;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

const summary = createSlice({
  name: 'summaryReducer',
  initialState,
  reducers: {
    SetSummaryState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetSummarySnapShot.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GetSummarySnapShot.fulfilled, (state, action) => ({
        ...state,
        success: true,
        loading: false,
        summaryData: action.payload
      }))
      .addCase(GetSummarySnapShot.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(DownloadWeeklySnapShot.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(DownloadWeeklySnapShot.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(DownloadWeeklySnapShot.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage || '',
        success: false,
        loading: false
      }));
  }
});

export const { SetSummaryState } = summary.actions;
export default summary.reducer;
