import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { AxiosBaseUrl } from '../../../config/axios-configuration';

import { ENDPOINTS } from '../../../utils/constants';

const axios = AxiosBaseUrl();

const initialState = {
  stores: null,
  loading: false,
  errMessage: null,
  success: false,
  storesData: null,
  storeName: '',
  storeState: '',
  storeRegion: '',
  sellerId: ''
};

export const GetAllStores = createAsyncThunk(
  ENDPOINTS.STORE.GET_ALL_STORES,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(ENDPOINTS.STORE.GET_ALL_STORES);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        error: 'Network Error'
      });
    }
  }
);

export const GetStoresData = createAsyncThunk(
  ENDPOINTS.STORE.GET_STORES_DATA,
  async ({
    searchKeyword,
    skip,
    limit
  }, { rejectWithValue }) => {
    try {
      let url = `${ENDPOINTS.STORE.GET_STORES_DATA}?skip=${skip}&limit=${limit}`;

      if (searchKeyword) {
        url += `&searchKeyword=${searchKeyword}`;
      }
      const response = await axios.get(url);

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        error: 'Network Error'
      });
    }
  }
);

export const UpdateStore = createAsyncThunk(
  ENDPOINTS.STORE.UPDATE_STORES_DATA,
  async ({
    storeId,
    storeName
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post(ENDPOINTS.STORE.UPDATE_STORES_DATA, {
        storeId,
        storeName
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        error: 'Network Error'
      });
    }
  }
);

export const DeleteStore = createAsyncThunk(
  ENDPOINTS.STORE.DELETE_STORE,
  async ({
    storeId
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post(ENDPOINTS.STORE.DELETE_STORE, {
        storeId
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        error: 'Network Error'
      });
    }
  }
);

export const GetRefreshToken = createAsyncThunk(
  ENDPOINTS.STORE.GET_REFRESH_TOKEN,
  async ({
    storeName,
    authCode,
    storeRegion,
    sellerId
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post(ENDPOINTS.STORE.GET_REFRESH_TOKEN, {
        storeName,
        authCode,
        storeRegion,
        sellerId
      });

      if (response.status === 200) {
        const marketplaceIds = response.data.allMarketplaces.join(',');
        const jobUrl = `${process.env.REACT_APP_JOB_URL}/script?method=StartAgendaJobs&storeId=${response.data.storeId}&jobType=SELLER_CENTRAL_JOBS&marketplaceIds=${marketplaceIds}`;

        fetch(jobUrl, {
          method: 'GET'
        });

        return { success: true };
      }

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        error: 'Network Error'
      });
    }
  }
);

export const GetAdvRefreshToken = createAsyncThunk(
  ENDPOINTS.STORE.GET_ADV_REFRESH_TOKEN,
  async ({
    storeName,
    authCode,
    storeRegion,
    sellerId
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post(ENDPOINTS.STORE.GET_ADV_REFRESH_TOKEN, {
        storeName,
        authCode,
        storeRegion,
        sellerId
      });

      if (response.status === 200) {
        const profileIdsArray = response.data.storeData[0]
          .adv_api.profileIds.map((store) => store.profileId);
        const profileIds = profileIdsArray.join(',');
        const jobUrl = `${process.env.REACT_APP_JOB_URL}/script?method=StartAgendaJobs&storeId=${response.data.storeData[0]._id}&jobType=ADVERTISING_JOBS&profileIds=${profileIds}`;

        fetch(jobUrl, {
          method: 'GET'
        });

        return { success: true };
      }

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        error: 'Network Error'
      });
    }
  }
);

export const UpdateStoreStatus = createAsyncThunk(
  ENDPOINTS.STORE.UPDATE_STORE_STATUS,
  async ({
    storeId
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post(ENDPOINTS.STORE.UPDATE_STORE_STATUS, {
        storeId
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        error: 'Network Error'
      });
    }
  }
);

const store = createSlice({
  name: 'storeReducer',
  initialState,
  reducers: {
    SetStoreState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    SetStoreInfo(state, action) {
      return {
        ...state,
        storeName: action.payload.storeName,
        storeState: action.payload.storeState,
        storeRegion: action.payload.storeRegion,
        sellerId: action.payload.sellerId
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllStores.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GetAllStores.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        success: true,
        stores: action.payload.stores
      }))
      .addCase(GetAllStores.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(GetStoresData.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GetStoresData.fulfilled, (state, action) => ({
        ...state,
        success: true,
        loading: false,
        storesData: action.payload
      }))
      .addCase(GetStoresData.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(UpdateStore.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(UpdateStore.fulfilled, (state, action) => ({
        ...state,
        success: true,
        loading: false,
        storesData: action.payload
      }))
      .addCase(UpdateStore.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(DeleteStore.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(DeleteStore.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(DeleteStore.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(GetRefreshToken.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GetRefreshToken.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(GetRefreshToken.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload?.err?.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(GetAdvRefreshToken.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GetAdvRefreshToken.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(GetAdvRefreshToken.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload?.err?.errorMessage || '',
        success: false,
        loading: false
      }));
    builder
      .addCase(UpdateStoreStatus.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(UpdateStoreStatus.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(UpdateStoreStatus.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload?.err?.errorMessage || '',
        success: false,
        loading: false
      }));
  }
});

export const {
  SetStoreState,
  SetStoreInfo
} = store.actions;
export default store.reducer;
