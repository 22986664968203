import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { AxiosBaseUrl } from '../../../config/axios-configuration';

import { ENDPOINTS } from '../../../utils/constants';

const axios = AxiosBaseUrl();

const initialState = {
  loading: false,
  errMessage: null,
  message: '',
  success: false,
  error: '',
  jobs: null,
  agendaTypes: null
};

export const GetAllAgendaTypes = createAsyncThunk(
  ENDPOINTS.JOBS.GET_ALL_AGENDA_TYPES,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(ENDPOINTS.JOBS.GET_ALL_AGENDA_TYPES);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        error: 'Network Error'
      });
    }
  }
);

export const GetAllJobs = createAsyncThunk(
  ENDPOINTS.JOBS.GET_ALL_JOBS,
  async ({
    storeId,
    agendaType,
    jobStateType,
    searchKeys,
    searchValues
  }, {
    rejectWithValue
  }) => {
    try {
      const requestData = {
        storeId,
        agendaType
      };

      if (jobStateType) {
        requestData.jobStateType = jobStateType;
      }

      if (searchKeys && searchValues) {
        requestData.searchKeys = searchKeys;
        requestData.searchValues = searchValues;
      }

      const response = await axios.post(ENDPOINTS.JOBS.GET_ALL_JOBS, requestData);

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const RequeueJob = createAsyncThunk(
  ENDPOINTS.JOBS.REQUEUE_JOB,
  async ({
    jobId,
    agendaType
  }, {
    rejectWithValue
  }) => {
    try {
      const response = await axios.post(ENDPOINTS.JOBS.REQUEUE_JOB, {
        jobId,
        agendaType
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const DeleteJob = createAsyncThunk(
  ENDPOINTS.JOBS.DELETE_JOB,
  async ({
    jobId,
    agendaType
  }, {
    rejectWithValue
  }) => {
    try {
      const response = await axios.post(ENDPOINTS.JOBS.DELETE_JOB, {
        jobId,
        agendaType
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

const job = createSlice({
  name: 'jobReducer',
  initialState,
  reducers: {
    SetJobState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllAgendaTypes.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GetAllAgendaTypes.fulfilled, (state, action) => ({
        ...state,
        success: true,
        loading: false,
        agendaTypes: action.payload.agendaTypes
      }))
      .addCase(GetAllAgendaTypes.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload || '',
        success: false,
        loading: false
      }));

    builder
      .addCase(GetAllJobs.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GetAllJobs.fulfilled, (state, action) => ({
        ...state,
        success: true,
        loading: false,
        jobs: action.payload.jobData
      }))
      .addCase(GetAllJobs.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage,
        success: false,
        loading: false
      }));

    builder
      .addCase(RequeueJob.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(RequeueJob.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(RequeueJob.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage,
        success: false,
        loading: false
      }));

    builder
      .addCase(DeleteJob.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(DeleteJob.fulfilled, (state) => ({
        ...state,
        success: true,
        loading: false
      }))
      .addCase(DeleteJob.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.errorMessage,
        success: false,
        loading: false
      }));
  }
});

export const { SetJobState } = job.actions;
export default job.reducer;
