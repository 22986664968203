import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ParentAsinReport = lazy(() => import('../pages/Products/component/ParentAsinReport'));
const Settings = lazy(() => import('../pages/Settings/component/UploadProductCost'));
const SnapShots = lazy(() => import('../pages/Summary/component/SnapShot'));
const AllJobs = lazy(() => import('../pages/Jobs/component/jobs'));
const StoreComponent = lazy(() => import('../pages/Store/component/store'));
const WaitingComponent = lazy(() => import('../pages/Store/component/waitingScreen'));
const AdvWaitingComponent = lazy(() => import('../pages/Store/component/advWaitingScreen'));
const EmailPage = lazy(() => import('../pages/Email/component/email'));
const Layout = lazy(() => import('../layout'));

const PrivateRoutes = () => ({
  path: '/',
  element: <Layout />,
  children: [
    {
      path: '/', element: <Navigate to="summary" replace />
    },
    {
      path: 'summary', element: <SnapShots />
    },
    {
      path: 'products', element: <ParentAsinReport />
    },
    {
      path: 'settings', element: <Settings />
    },
    {
      path: 'jobs', element: <AllJobs />
    },
    {
      path: 'stores', element: <StoreComponent />
    },
    {
      path: 'emails', element: <EmailPage />
    },
    {
      path: 'auth/sp-api', element: <WaitingComponent />
    },
    {
      path: 'auth/adv-api', element: <AdvWaitingComponent />
    }
  ]
});

export default PrivateRoutes;
