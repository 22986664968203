import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { AxiosBaseUrl } from '../../../config/axios-configuration';

import { ENDPOINTS } from '../../../utils/constants';

const axios = AxiosBaseUrl();

const initialState = {
  url: null,
  key: null,
  storeId: null,
  loading: false,
  errMessage: null,
  message: '',
  success: false,
  error: ''
};

export const GeneratePreSignedUrl = createAsyncThunk(
  ENDPOINTS.SETTINGS.GET_PRE_SIGNED_URL,
  async ({
    fileName,
    fileType
  }, {
    rejectWithValue
  }) => {
    try {
      const response = await axios.post(ENDPOINTS.SETTINGS.GET_PRE_SIGNED_URL, {
        fileName,
        fileType
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const UploadFileToURL = createAsyncThunk(
  'setting/UploadFileToURL',
  async ({
    file,
    key,
    storeId,
    url
  }, { rejectWithValue }) => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type
        },
        body: file
      });

      if (response.ok) {
        const jobUrl = `${process.env.REACT_APP_JOB_URL}/script?method=StartSingleJob&storeId=${storeId}&jobName=update-shipping-and-cost-price&s3Key=${key}`;

        fetch(jobUrl, {
          method: 'GET'
        });

        return { success: true };
      }

      return rejectWithValue({
        success: false,
        error: 'File upload failed. Server returned an error status.'
      });
    } catch (error) {
      return rejectWithValue({
        success: false,
        error: 'File upload failed. Network error.'
      });
    }
  }
);

const setting = createSlice({
  name: 'settingReducer',
  initialState,
  reducers: {
    SetSettingState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GeneratePreSignedUrl.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(GeneratePreSignedUrl.fulfilled, (state, action) => ({
        ...state,
        success: true,
        loading: false,
        url: action.payload.url,
        key: action.payload.key,
        storeId: action.payload.storeId
      }))
      .addCase(GeneratePreSignedUrl.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.err.error?.details[0].message || action.payload.err.errorMessage,
        success: false,
        loading: false
      }));
    builder
      .addCase(UploadFileToURL.pending, (state) => ({
        ...state,
        loading: true,
        success: false
      }))
      .addCase(UploadFileToURL.fulfilled, (state) => ({
        ...state,
        loading: false,
        success: true,
        message: 'File Uploaded Successfully'
      }))
      .addCase(UploadFileToURL.rejected, (state, action) => ({
        ...state,
        errMessage: action.payload.error,
        error: 'File Upload failed. Server returned an error status.',
        loading: false,
        success: false
      }));
  }
});

export const { SetSettingState } = setting.actions;
export default setting.reducer;
