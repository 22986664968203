import { thunk } from 'redux-thunk';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistReducer, createTransform } from 'redux-persist';
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import auth from '../slice/auth';
import job from '../slice/job';
import email from '../slice/email';
import product from '../slice/product';
import setting from '../slice/setting';
import store from '../slice/store';
import summary from '../slice/summary';

const authTransform = createTransform(
  (inboundState) => ({
    token: inboundState.token,
    storeName: inboundState.storeName,
    storeState: inboundState.storeState,
    storeRegion: inboundState.storeRegion,
    sellerId: inboundState.sellerId
  }),
  null
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'store'],
  transforms: [authTransform]
};

const reducers = combineReducers({
  auth,
  job,
  email,
  product,
  setting,
  store,
  summary
});

const rootReducer = (state, action) => {
  if (action.type === 'authReducer/Logout') {
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: () => [thunk, logger],
  devTools: true
});
