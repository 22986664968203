export const ENDPOINTS = {
  AUTH: {
    SIGN_UP: '/auth/sign-up',
    SIGN_IN: '/auth/sign-in',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password'
  },
  SETTINGS: {
    GET_PRE_SIGNED_URL: '/other/get-pre-signed-url'
  },
  STORE: {
    GET_ALL_STORES: '/store/get-all-stores',
    GET_STORES_DATA: '/store/get-stores-data',
    UPDATE_STORES_DATA: '/store/update-stores-data',
    UPDATE_STORE_STATUS: '/store/update-store-status',
    DELETE_STORE: '/store/delete-store',
    GET_REFRESH_TOKEN: '/store/get-refresh-token',
    GET_ADV_REFRESH_TOKEN: '/store/get-adv-refresh-token'
  },
  SUMMARY: {
    GET_SUMMARY_SNAP_SHOT: '/summary/get-summary',
    GET_PARENT_ASIN_SUMMARY: '/summary/get-parent-asin-summary',
    GET_CHILD_ASIN_SUMMARY: '/summary/get-child-asin-summary'
  },
  NON_SECURE_ROUTES: {
    DOWNLOAD_WEEKLY_SNAPSHOT: '/non-secure/download-weekly-snapshot',
    DOWNLOAD_PARENT_ASIN_SUMMARY: '/non-secure/download-parent-asin-summary',
    DOWNLOAD_CHILD_ASIN_SUMMARY: '/non-secure/download-child-asin-summary',
    SAVE_COGS_ACOS_REPORT_IDS: '/non-secure/save-cogs-acos-report-ids',
    DOWNLOAD_COGS_ACOS_REPORT: '/non-secure/download-cogs-acos-report'
  },
  PRODUCT: {
    GET_COST_AND_FIELDS: '/product/get-cost-and-fields',
    UPDATE_PRODUCT_FEES: '/product/update-product-fees'
  },
  JOBS: {
    GET_ALL_AGENDA_TYPES: '/jobs/get-all-agenda-types',
    GET_ALL_JOBS: '/jobs/get-job',
    REQUEUE_JOB: '/jobs/requeue-job',
    DELETE_JOB: '/jobs/delete-job'
  },
  EMAIL: {
    ADD_EMAIL: '/email/add-email',
    GET_ALL_EMAIL: '/email/get-all-emails',
    DELETE_EMAIL: '/email/delete-email',
    UPDATE_EMAIL: '/email/update-email'
  }
};

export const REQUIRED_COLUMNS = {
  UPDATE_SHIPPING_AND_COST: ['marketplace', 'asin', 'sellerSku', 'shippingCost', 'manufacturingCost']
};

export const SORT_ORDERS = {
  ascend: 'asc',
  descend: 'desc'
};

export const TABLE_HEADERS = {

  SUMMARY_HEADERS: [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 85
    },
    {
      title: 'Session 7 Day',
      dataIndex: 'sessions7Day',
      key: 'sessions7Day',
      width: 75
    },
    {
      title: 'Session 30 Day',
      dataIndex: 'sessions30Day',
      key: 'sessions30Day',
      width: 75
    },
    {
      title: 'Unit Ordered 7 Day',
      dataIndex: 'unitsOrdered7Day',
      key: 'unitsOrdered7Day',
      width: 100
    },
    {
      title: 'Unit Ordered 30 Day',
      dataIndex: 'unitsOrdered30Day',
      key: 'unitsOrdered30Day',
      width: 100
    },
    {
      title: 'Total Sales $ 7 Day',
      dataIndex: 'totalSales7Day',
      key: 'totalSales7Day',
      width: 100
    },
    {
      title: 'Total Organic Sales $ 7 Day',
      dataIndex: 'totalOrganicSales7Day',
      key: 'totalOrganicSales7Day',
      width: 140
    },
    {
      title: 'Total Sales $ 30 Day',
      dataIndex: 'totalSales30Day',
      key: 'totalSales30Day',
      width: 105
    },
    {
      title: 'Total Organic Sales $ 30 Day',
      dataIndex: 'totalOrganicSales30Day',
      key: 'totalOrganicSales30Day',
      width: 135
    },
    {
      title: 'Total PPC Sales $ 7 Day',
      dataIndex: 'totalPPCSales7Day',
      key: 'totalPPCSales7Day',
      width: 120
    },
    {
      title: '% Attributed 7 Day',
      dataIndex: 'attributed7Day',
      key: 'attributed7Day',
      width: 95
    },
    {
      title: 'Total PPC Spend ($) 7 Day',
      dataIndex: 'totalPPCSpend7Day',
      key: 'totalPPCSpend7Day',
      width: 135
    },
    {
      title: 'ACOS 7 Day',
      dataIndex: 'totalAcos7Day',
      key: 'totalAcos7Day',
      width: 85
    },
    {
      title: 'Blended TACOS 7 Day',
      dataIndex: 'blendedTotalAcos7Day',
      key: 'blendedTotalAcos7Day',
      width: 110
    },
    {
      title: 'Total PPC Sales $ 30 Day',
      dataIndex: 'totalPPCSales30Day',
      key: 'totalPPCSales30Day',
      width: 120
    },
    {
      title: '% Attributed 30 Day',
      dataIndex: 'attributed30Day',
      key: 'attributed30Day',
      width: 95
    },
    {
      title: 'Total PPC Spend ($) 30 Day',
      dataIndex: 'totalPPCSpend30Day',
      key: 'totalPPCSpend30Day',
      width: 130
    },
    {
      title: 'ACOS 30 Day',
      dataIndex: 'totalAcos30Day',
      key: 'totalAcos30Day',
      width: 85
    },
    {
      title: 'Blended ACOS 30 Day',
      dataIndex: 'blendedTotalAcos30Day',
      key: 'blendedTotalAcos30Day',
      width: 110
    }
  ],

  PARENT_ASIN_HEADERS: [
    {
      title: 'select',
      dataIndex: 'selectProduct',
      key: 'selectProduct',
      width: 60
    },
    {
      title: 'Test Date',
      dataIndex: 'testDate',
      key: 'testDate',
      width: 90
    },
    {
      title: 'Parent ASIN',
      dataIndex: 'parentAsin',
      key: 'parentAsin',
      width: 95
    },
    {
      title: 'Product ID',
      dataIndex: 'productId',
      key: 'productId',
      width: 120
    },
    {
      title: 'Product',
      dataIndex: 'title',
      key: 'title',
      width: 150
    },
    {
      title: 'Best Seller Rank (BSR)',
      dataIndex: 'bestsellerRank',
      key: 'bestsellerRank',
      width: 150
    },
    {
      title: 'Session 7 Day',
      dataIndex: 'sessions7Day',
      key: 'sessions7Day',
      width: 75
    },
    {
      title: 'Session 30 Day',
      dataIndex: 'sessions30Day',
      key: 'sessions30Day',
      width: 75
    },
    {
      title: 'Unit Ordered 7 Day',
      dataIndex: 'unitsOrdered7Day',
      key: 'unitsOrdered7Day',
      width: 100
    },
    {
      title: 'Unit Session % / Conv Rate 7 Day',
      dataIndex: 'totalUnitSessionConvRate7Day',
      key: 'totalUnitSessionConvRate7Day',
      width: 120
    },
    {
      title: 'Unit Ordered 30 Day',
      dataIndex: 'unitsOrdered30Day',
      key: 'unitsOrdered30Day',
      width: 100
    },
    {
      title: 'Total Sales $ 7 Day',
      dataIndex: 'totalSales7Day',
      key: 'totalSales7Day',
      width: 100
    },
    {
      title: 'B2B Units Ordered 7 Day',
      dataIndex: 'unitsOrderedB2B7Day',
      key: 'unitsOrderedB2B7Day',
      width: 120
    },
    {
      title: 'B2B Unit Session % / Conv Rate 7 Day',
      dataIndex: 'totalB2BUnitSessionConvRate7Day',
      key: 'totalB2BUnitSessionConvRate7Day',
      width: 140
    },
    {
      title: 'B2B Sales $ - 7 Day',
      dataIndex: 'totalB2BSales7Day',
      key: 'totalB2BSales7Day',
      width: 100
    },
    {
      title: 'Total Organic Sales $ 7 Day',
      dataIndex: 'totalOrganicSales7Day',
      key: 'totalOrganicSales7Day',
      width: 140
    },
    {
      title: 'Total Sales $ 30 Day',
      dataIndex: 'totalSales30Day',
      key: 'totalSales30Day',
      width: 105
    },
    {
      title: 'Total Organic Sales $ 30 Day',
      dataIndex: 'totalOrganicSales30Day',
      key: 'totalOrganicSales30Day',
      width: 135
    },
    {
      title: 'Unit Session % / Conv Rate 30 Day',
      dataIndex: 'totalUnitSessionConvRate30Day',
      key: 'totalUnitSessionConvRate30Day',
      width: 135
    },
    {
      title: 'B2B Units Ordered 30 Day',
      dataIndex: 'unitsOrderedB2B30Day',
      key: 'unitsOrderedB2B30Day',
      width: 135
    },
    {
      title: 'B2B Unit Session % / Conv Rate 30 Day',
      dataIndex: 'totalB2BUnitSessionConvRate30Day',
      key: 'totalB2BUnitSessionConvRate30Day',
      width: 135
    },
    {
      title: 'B2B Sales $ 30 Day',
      dataIndex: 'totalB2BSales30Day',
      key: 'totalB2BSales30Day',
      width: 100
    },
    {
      title: 'Total PPC Sales $ 7 Day',
      dataIndex: 'totalPPCSales7Day',
      key: 'totalPPCSales7Day',
      width: 120
    },
    {
      title: '% Attributed 7 Day',
      dataIndex: 'attributed7Day',
      key: 'attributed7Day',
      width: 95
    },
    {
      title: 'Total PPC Spend ($) 7 Day',
      dataIndex: 'totalPPCSpend7Day',
      key: 'totalPPCSpend7Day',
      width: 135
    },
    {
      title: 'ACOS 7 Day',
      dataIndex: 'totalAcos7Day',
      key: 'totalAcos7Day',
      width: 85
    },
    {
      title: 'Blended TACOS 7 Day',
      dataIndex: 'blendedTotalAcos7Day',
      key: 'blendedTotalAcos7Day',
      width: 110
    },
    {
      title: 'Total PPC Sales $ 30 Day',
      dataIndex: 'totalPPCSales30Day',
      key: 'totalPPCSales30Day',
      width: 120
    },
    {
      title: '% Attributed 30 Day',
      dataIndex: 'attributed30Day',
      key: 'attributed30Day',
      width: 95
    },
    {
      title: 'Total PPC Spend ($) 30 Day',
      dataIndex: 'totalPPCSpend30Day',
      key: 'totalPPCSpend30Day',
      width: 130
    },
    {
      title: 'ACOS 30 Day',
      dataIndex: 'totalAcos30Day',
      key: 'totalAcos30Day',
      width: 85
    },
    {
      title: 'Blended ACOS 30 Day',
      dataIndex: 'blendedTotalAcos30Day',
      key: 'blendedTotalAcos30Day',
      width: 110
    },
    {
      title: 'FBA Fees Blackout 30 Day',
      dataIndex: 'fbaFeeBlackout30Day',
      key: 'fbaFeeBlackout30Day',
      width: 110
    },
    {
      title: 'Total Profit 30 Day',
      dataIndex: 'totalProfit30Day',
      key: 'totalProfit30Day',
      width: 95
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 80
    }
  ],

  CHILD_ASIN_HEADERS: [
    {
      title: 'select',
      dataIndex: 'selectProduct',
      key: 'selectProduct',
      width: 60
    },
    {
      title: 'Test Date',
      dataIndex: 'testDate',
      key: 'testDate',
      width: 90
    },
    {
      title: 'Parent ASIN',
      dataIndex: 'parentAsin',
      key: 'parentAsin',
      width: 95
    },
    {
      title: 'Child ASIN',
      dataIndex: 'childAsin',
      key: 'childAsin',
      width: 95
    },
    {
      title: 'Product ID',
      dataIndex: 'productId',
      key: 'productId',
      width: 120
    },
    {
      title: 'Product',
      dataIndex: 'title',
      key: 'title',
      width: 150
    },
    {
      title: 'Best Seller Rank (BSR)',
      dataIndex: 'bestsellerRank',
      key: 'bestsellerRank',
      width: 150
    },
    {
      title: 'Session 7 Day',
      dataIndex: 'sessions7Day',
      key: 'sessions7Day',
      width: 75
    },
    {
      title: 'Session 30 Day',
      dataIndex: 'sessions30Day',
      key: 'sessions30Day',
      width: 75
    },
    {
      title: 'Unit Ordered 7 Day',
      dataIndex: 'unitsOrdered7Day',
      key: 'unitsOrdered7Day',
      width: 100
    },
    {
      title: 'Unit Session % / Conv Rate 7 Day',
      dataIndex: 'totalUnitSessionConvRate7Day',
      key: 'totalUnitSessionConvRate7Day',
      width: 120
    },
    {
      title: 'Unit Ordered 30 Day',
      dataIndex: 'unitsOrdered30Day',
      key: 'unitsOrdered30Day',
      width: 100
    },
    {
      title: 'Total Sales $ 7 Day',
      dataIndex: 'totalSales7Day',
      key: 'totalSales7Day',
      width: 100
    },
    {
      title: 'B2B Units Ordered 7 Day',
      dataIndex: 'unitsOrderedB2B7Day',
      key: 'unitsOrderedB2B7Day',
      width: 120
    },
    {
      title: 'B2B Unit Session % / Conv Rate 7 Day',
      dataIndex: 'totalB2BUnitSessionConvRate7Day',
      key: 'totalB2BUnitSessionConvRate7Day',
      width: 140
    },
    {
      title: 'B2B Sales $ - 7 Day',
      dataIndex: 'totalB2BSales7Day',
      key: 'totalB2BSales7Day',
      width: 100
    },
    {
      title: 'Total Organic Sales $ 7 Day',
      dataIndex: 'totalOrganicSales7Day',
      key: 'totalOrganicSales7Day',
      width: 140
    },
    {
      title: 'Total Sales $ 30 Day',
      dataIndex: 'totalSales30Day',
      key: 'totalSales30Day',
      width: 105
    },
    {
      title: 'Total Organic Sales $ 30 Day',
      dataIndex: 'totalOrganicSales30Day',
      key: 'totalOrganicSales30Day',
      width: 135
    },
    {
      title: 'Unit Session % / Conv Rate 30 Day',
      dataIndex: 'totalUnitSessionConvRate30Day',
      key: 'totalUnitSessionConvRate30Day',
      width: 135
    },
    {
      title: 'B2B Units Ordered 30 Day',
      dataIndex: 'unitsOrderedB2B30Day',
      key: 'unitsOrderedB2B30Day',
      width: 135
    },
    {
      title: 'B2B Unit Session % / Conv Rate 30 Day',
      dataIndex: 'totalB2BUnitSessionConvRate30Day',
      key: 'totalB2BUnitSessionConvRate30Day',
      width: 135
    },
    {
      title: 'B2B Sales $ 30 Day',
      dataIndex: 'totalB2BSales30Day',
      key: 'totalB2BSales30Day',
      width: 100
    },
    {
      title: 'Total PPC Sales $ 7 Day',
      dataIndex: 'totalPPCSales7Day',
      key: 'totalPPCSales7Day',
      width: 120
    },
    {
      title: '% Attributed 7 Day',
      dataIndex: 'attributed7Day',
      key: 'attributed7Day',
      width: 95
    },
    {
      title: 'Total PPC Spend ($) 7 Day',
      dataIndex: 'totalPPCSpend7Day',
      key: 'totalPPCSpend7Day',
      width: 135
    },
    {
      title: 'ACOS 7 Day',
      dataIndex: 'totalAcos7Day',
      key: 'totalAcos7Day',
      width: 85
    },
    {
      title: 'Blended TACOS 7 Day',
      dataIndex: 'blendedTotalAcos7Day',
      key: 'blendedTotalAcos7Day',
      width: 110
    },
    {
      title: 'Total PPC Sales $ 30 Day',
      dataIndex: 'totalPPCSales30Day',
      key: 'totalPPCSales30Day',
      width: 120
    },
    {
      title: '% Attributed 30 Day',
      dataIndex: 'attributed30Day',
      key: 'attributed30Day',
      width: 95
    },
    {
      title: 'Total PPC Spend ($) 30 Day',
      dataIndex: 'totalPPCSpend30Day',
      key: 'totalPPCSpend30Day',
      width: 130
    },
    {
      title: 'ACOS 30 Day',
      dataIndex: 'totalAcos30Day',
      key: 'totalAcos30Day',
      width: 85
    },
    {
      title: 'Blended ACOS 30 Day',
      dataIndex: 'blendedTotalAcos30Day',
      key: 'blendedTotalAcos30Day',
      width: 110
    },
    {
      title: 'FBA Fees Blackout 30 Day',
      dataIndex: 'fbaFeeBlackout30Day',
      key: 'fbaFeeBlackout30Day',
      width: 110
    },
    {
      title: 'Total Profit 30 Day',
      dataIndex: 'totalProfit30Day',
      key: 'totalProfit30Day',
      width: 95
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 80
    }
  ],

  PRODUCT_DESCRIPTION: [
    {
      key: '1',
      label: 'ASIN',
      children: '',
      span: 3
    },
    {
      key: '2',
      label: 'Product Price',
      children: '',
      span: 3
    },
    {
      key: '3',
      label: 'Fulfillment Fee',
      children: '',
      span: 1.5
    },
    {
      key: '4',
      label: 'Manufacturing Cost',
      children: '',
      span: 1.5
    },
    {
      key: '5',
      label: 'Referral Fee',
      children: '',
      span: 1.5
    },
    {
      key: '6',
      label: 'Shipping Cost',
      children: '',
      span: 1.5
    },
    {
      key: '7',
      label: 'AMZ Fee (Fulfillment + Referral)',
      children: '',
      span: 1.5
    },
    {
      key: '8',
      label: 'Product & Shipping Cost',
      children: '',
      span: 1.5
    },
    {
      key: '9',
      label: 'Profit Margin',
      children: '',
      span: 1.5
    },
    {
      key: '10',
      label: 'COGS',
      children: '',
      span: 1.5
    },
    {
      key: '11',
      label: 'ACOS',
      children: '',
      span: 3
    },
    {
      key: '12',
      label: 'Break Even ACOS',
      children: '',
      span: 1.5
    },
    {
      key: '13',
      label: 'Target ACOS',
      children: '',
      span: 1.5
    },
    {
      key: '14',
      label: 'Target Blended ACOS - TACOS',
      children: '',
      span: 3
    }
  ],

  JOBS_PAGE_HEADERS: [
    {
      title: 'Job Name',
      dataIndex: 'jobName',
      key: 'jobName',
      width: 150
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      width: 190
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
      width: 150
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150
    },
    {
      title: 'Next Run At',
      dataIndex: 'nextRunAt',
      key: 'nextRunAt',
      width: 150
    },
    {
      title: 'Locked At',
      dataIndex: 'lockedAt',
      key: 'lockedAt',
      width: 150
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions'
    }
  ],

  EMAIL_PAGE_HEADERS: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 30
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 40
    },
    {
      title: 'Stores',
      dataIndex: 'stores',
      key: 'stores',
      width: 40
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 40
    }
  ],

  STORE_PAGE_HEADERS: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 30
    },
    {
      title: 'Marketplaces',
      dataIndex: 'marketplaces',
      key: 'marketplaces',
      width: 70
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 40
    },
    {
      title: 'Connect Advertising',
      dataIndex: 'advertising',
      key: 'advertising',
      width: 40
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 40
    }
  ]
};

export const JOB_STATES = {
  COMPLETED: '_COMPLETED_',
  FAILED: '_FAILED_',
  IN_PROGRESS: '_IN_PROGRESS_',
  RETRY: '_RETRY_',
  STARTED: '_STARTED_'
};

export default {
  ENDPOINTS,
  JOB_STATES,
  REQUIRED_COLUMNS,
  TABLE_HEADERS
};
